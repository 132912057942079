<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Choices from "choices.js";

import { useTaskApi } from "@/apis/task";

/**
 * Advanced table component
 */
export default {
  page: {
    title: '生产加工',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader

  },
  data() {
    return {
      tableData: [],
      listingData: [],
      title: this.$t('produce.list_page.sub_title'),
      items: [
        {
          text: this.$t('produce.list_page.nav_first_item'),
          href: "/",
        },
        {
          text: this.$t('produce.list_page.nav_second_item'),
          active: true,
        },
      ],

      totalRows: 1,
      perPage: 20,
      currentPage: 1,


      fields: [
        {
          key: "task_number",
          label: '任务编号',
          sortable: true,
        },


        {
          key: "Project",
          label: '所属项目',
          sortable: true,
        },


        {
          key: "Manager",
          label: '任务下发人',
          sortable: true,
        },

        {
          key: "SchDate",
          label: '预计完成时间',
          sortable: true,
        },
        {key : 'Remarks', label :'说明'},
        {key : 'Status', label :'状态'},

        {
          key: "Pos",
          label: this.$t('produce.list_page.table_fields.issue_action'),
          sortable: false,
        },
      ],
      p_list_fields: [
        { key: 'Code', label: this.$t('produce.new_task.list_table_field.code') },
        { key: 'PNumber', label: this.$t('produce.new_task.list_table_field.pnumber') },
        { key: 'Team', label: '班组' },
        { key: 'Leader', label: '责任人' },
        { key: 'PCount', label: '投产数量' },
      ],

      current_task: {},
      task_status :'SUBMITTED'
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },


  },
  mounted() {

    new Choices('#task_status', {})
    this.query_task();


  },

  methods: {

    query_task() {
      useTaskApi().list_task({status : [this.task_status]}).then(res => {

        if (res.err == 0) {
          this.tableData = []
          res.data.map(e => {
            this.tableData.push(e)
          })
          // Set the initial number of items
          this.totalRows = this.tableData.length;
        }
      })
    },

    goto_new_task() {
      this.$router.push({ 'name': 'task-new' })
    },

    delete_task(task) {

      this.$alertify.confirmWithTitle('确认',
        "确定要删除此任务吗？ ！！！ 所有投产清单将无效！！！！",
        () => {
          useTaskApi().delete_task(task.task_number, {}).then(res => {
            if (res.err == 0) {
              this.$alertify.message("删除成功");
              this.query_task();
            } else {
              this.$alertify.error("删除失败 " + res.err + ":" + res.msg);
            }

          })
        },
        () => {

        }
      );

    },


    confirm_finish_task() {
      this.$alertify.confirmWithTitle('确认',
        "确定要标记此此任务结束吗？",
        () => {
          useTaskApi().finish_task(this.current_task.task_number, { remarks: this.current_task.remarks }).then(res => {
            if (res.err == 0) {
              this.$alertify.message("标记成功");

              this.$bvModal.hide('finish_task_dialog')
              this.query_task();
            } else {
              this.$alertify.error("标记失败 " + res.err + ":" + res.msg);
            }

          })
        },
        () => {

        }
      );
    },

    show_task(task) {
      this.$router.push({ name: 'task-detail', query: { parent_task: task.task_number } })
    },


    show_finished_dialog(task) {
      this.current_task = task
      this.$bvModal.show('finish_task_dialog')
    },

    get_task_status(task) {
      if (task.task_status == 'SUBMITTED') {
        return '进行中'
      } else if (task.task_status == 'DELETED') {
        return '已废弃'
      } else if (task.task_status == 'DONE') {
        return '已完成'
      } else {
        return ''
      }
    }


  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal id="finish_task_dialog" title="投产任务" size="lg" centered hide-footer :static="true">
      <div class="row mb-3">
        <div class="col-md-8">
          <label class="form-label">说明</label>
          <input type="text" class="form-control" v-model="current_task.remarks" maxlength="100" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
            <button type="button" class="btn btn-secondary w-sm" @click="$bvModal.hide('finish_task_dialog')"> 取消
            </button>
            <button type="submit" class="btn btn-primary w-sm" @click="confirm_finish_task"> 确认
            </button>
          </div>
        </div>
      </div>

    </b-modal>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 col-md-3">
                <div class="dataTables_length">

                </div>
              </div>

              <div class="col-sm-6 col-md-3">
                <div class="row">
                  <div class="col-md-2">
                    <label class="d-inline-flex align-items-center"> 任务状态&nbsp;</label>
                  </div>
                  <div class="col-md-6">
                    <select id="task_status" class="form-control" v-model="task_status">
                      <option value="SUBMITTED" selected>进行中</option>
                      <option value="DONE">已完成</option>
                      <option value="DELETED">已废弃</option>
                    </select>

                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-3">
              </div>
              <!-- Search -->
              <div class="col-sm-6 col-md-3">
                <div class=" d-flex flex-wrap  justify-content-md-end gap-2">
                  <b-button variant="secondary" @click="query_task">查询</b-button>
                  <b-button variant="secondary" @click="goto_new_task">{{ $t('produce.list_page.btn.new_task')}}</b-button>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" style="min-height: 60vh;">
              <b-table class="datatables" :items="tableData" :fields="fields" responsive="sm" :per-page="perPage">

                <template #cell(Progress)="data">
                  {{ data.item.fields.progress }}
                </template>

                <template #cell(Manager)="data">
                  {{ data.item.fields.initator }}
                </template>

                <template #cell(Project)="data">
                  {{ data.item.fields.project }}
                </template>

                <template #cell(SchDate)="data">
                  {{ data.item.fields.plan_date }}
                </template>
                <template #cell(Remarks)="data">
                  {{ data.item.fields.remarks }}
                </template>

                

                <template #cell(Status)="data">
                  {{ get_task_status(data.item) }}
                </template>

                <template #cell(Pos)="data">
                  <b-dropdown variant="white" toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                    </template>
                    <li>
                      <b-dropdown-item @click="show_task(data.item)"><i
                          class="mdi mdi-pencil font-size-16 text-success me-1"></i> 投产清单
                      </b-dropdown-item>
                    </li>

                    <li>
                      <b-dropdown-item @click="show_finished_dialog(data.item)" :disabled="data.item.task_status != 'SUBMITTED'"><i
                          class="mdi mdi-pencil font-size-16 text-success me-1"></i> 标记完成
                      </b-dropdown-item>
                    </li>
                    <li>
                      <b-dropdown-item @click="delete_task(data.item)" :disabled="data.item.task_status != 'SUBMITTED'"><i
                          class="mdi mdi-pencil font-size-16 text-success me-1"></i> 删除
                      </b-dropdown-item>
                    </li>


                  </b-dropdown>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
